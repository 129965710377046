import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Input,
  Label,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getClientQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";

const ClientView = () => {
  const { clientId } = useParams();
  const { t } = useTranslation();

  const { data: client } = useQuery({
    ...getClientQuery(clientId),
  });
  console.log(client);

  document.title = "Client Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Client Details")} pageTitle={t("client")} />
        {client?.id && (
          <Row>
            <Col xl={9}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("client_data")}
                    </h5>
                    <div className="flex-shrink-0">
                      {client.state === 1 ? (
                        <div className="text-success">{t("state1")}</div>
                      ) : (
                        <div className="text-danger">{t("state2")}</div>
                      )}
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="d-flex flex-column align-item-center gap-3">
                  <Row style={{ display: "flex", rowGap: "1rem" }}>
                    <Col xl={12}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Номер телефона"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={client.phoneNumber}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "15px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Тип документа"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={"Паспорт"}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Паспортные данные"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={`${client.bioPassportSerial} ${client.bioPassportNumber}`}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Фамилия"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={`${client.lastName}`}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Имя"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={`${client.firstName}`}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Отчество"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={`${client.surname}`}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label
                          htmlFor="exampleInputdate"
                          className="form-label"
                        >
                          {"Дата рождения"}
                        </Label>
                        <Flatpickr
                          className="form-control fs-5"
                          options={{
                            dateFormat: "d.m.Y",
                            defaultDate: [`${client.birthDate}`],
                          }}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10.5px 11px",
                          }}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col xl={12}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Адрес проживания"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={client.address}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "15px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"ПИНФЛ"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={`${client.pinfl}`}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div>
                        <Label htmlFor="valueInput" className="form-label">
                          {"Номер карты"}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          disabled={true}
                          defaultValue={""}
                          style={{
                            backgroundColor: "transparent",
                            padding: "10px 10px",
                          }}
                        />
                      </div>
                    </Col>
                    {client.scoring && (
                      <>
                        <Col xl={12}>
                          <h5
                            className="card-title flex-grow-1 mb-0"
                            style={{ fontSize: "20px", fontWeight: 700 }}
                          >
                            {t("Предварительный скоринг")}
                          </h5>
                        </Col>
                        <Col xl={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {"Балл"}
                            </Label>
                            <Input
                              type="text"
                              className="form-control fs-5"
                              id="valueInput"
                              disabled={true}
                              defaultValue={client.scoring.ball}
                              style={{
                                backgroundColor: "transparent",
                                padding: "10px 10px",
                              }}
                            />
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {"Доступная сумма"}
                            </Label>
                            <Input
                              type="text"
                              className="form-control fs-5"
                              id="valueInput"
                              disabled={true}
                              defaultValue={client.scoring.enableSumma}
                              style={{
                                backgroundColor: "transparent",
                                padding: "10px 10px",
                              }}
                            />
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {"Комментарий"}
                            </Label>
                            <Input
                              type="text"
                              className="form-control fs-5"
                              id="valueInput"
                              defaultValue={client.scoring.comment}
                              disabled={true}
                              style={{
                                backgroundColor: "transparent",
                                padding: "15px 10px",
                              }}
                            />
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div>
                            <Label htmlFor="valueInput" className="form-label">
                              {"Причина"}
                            </Label>
                            <Input
                              type="text"
                              className="form-control fs-5"
                              id="valueInput"
                              defaultValue={"Неверные данные в заявке"}
                              disabled={true}
                              style={{
                                backgroundColor: "transparent",
                                padding: "15px 10px",
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ClientView;
