import { Controller } from "react-hook-form";
import { FormFeedback, Label, Input as InputB } from "reactstrap";
import { useTranslation } from "react-i18next";

const onKeyDown = (type, e, isInteger) => {
  switch (type) {
    case "number":
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "+" ||
        e.key === "-" ||
        e.key === "," ||
        (isInteger ? e.key === "." : false)
      ) {
        e.preventDefault();
      }
      break;
    default:
      return null;
  }
};

export const Input = ({
  name,
  control,
  errors,
  label,
  inputProps = {
    type: "text",
  },
  leftElement,
  rightElement,
  isInteger,
  maxLength,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {label && (
        <Label htmlFor="email" className="form-label" for={name}>
          {t(label)}
        </Label>
      )}
      {control && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div className="input-group">
              {leftElement && (
                <span className="input-group-text">{leftElement}</span>
              )}
              <InputB
                type="text"
                className="form-control"
                name={name}
                onKeyDown={(e) => onKeyDown(inputProps.type, e, isInteger)}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  if (maxLength) {
                    if (e.target.value.length <= maxLength) {
                      field.onChange(e);
                    }
                  } else {
                    field.onChange(e);
                  }
                }}
                {...inputProps}
                {...field}
              />
              {rightElement && (
                <span className="input-group-text">{rightElement}</span>
              )}
            </div>
          )}
        />
      )}
      {errors?.[name] && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {t(errors[name]?.message)}
        </div>
      )}
    </div>
  );
};
