import request from "../utils/axios";

export const getOrders = (params) => {
  return request({ method: "GET", url: "/orders", params });
};

export const getOrder = (id) => {
  return request({ method: "GET", url: `/orders/${id}` });
};

export const getContracts = (params) => {
  return request({ method: "GET", url: "/contracts", params });
};

export const getOrganizations = (params) => {
  return request({ method: "GET", url: "/organizations", params });
};
export const getOrganization = (id) => {
  return request({ method: "GET", url: `/organizations/${id}` });
};

export const getClients = (params) => {
  return request({ method: "GET", url: "/clients", params });
};

export const getClient = (id) => {
  return request({ method: "GET", url: `/clients/${id}` });
};

export const getProducts = (params) => {
  return request({ method: "GET", url: "/v1/products", params });
};

export const getProduct = (id) => {
  return request({ method: "GET", url: `/v1/products/${id}` });
};

export const getProductsCard = (productId) => {
  return request({
    method: "GET",
    url: `/v1/products/variations/${productId}`,
  });
};

export const getModerations = (params) => {
  return request({ method: "GET", url: "/v1/moderations", params });
};

export const getModeration = (moderId) => {
  return request({ method: "GET", url: `/v1/moderations/id?id=${moderId}` });
};

export const getCategories = (params) => {
  return request({ method: "GET", url: "/v1/categories", params });
};
export const getCategory = (id) => {
  return request({ method: "GET", url: `/v1/categories/${id}` });
};
export const deleteCategories = (id) => {
  return request({ method: "DELETE", url: `/v1/categories/${id}` });
};

export const getFilters = (params) => {
  return request({ method: "GET", url: "/v1/filters", params });
};

export const deleteFilters = (id) => {
  return request({ method: "DELETE", url: `/v1/filters/${id}` });
};

export const getAttributes = (params) => {
  return request({ method: "GET", url: "/v1/attributes", params });
};
export const getAttribute = (id) => {
  return request({ method: "GET", url: `/v1/attributes/${id}` });
};
export const getOrgFormalType = () => {
  return request({ method: "GET", url: "/organizations/formal-types" });
};
export const getRegions = () => {
  return request({ method: "GET", url: "/regions" });
};

// CRUD *****************************************

export const createCategories = (data) => {
  return request({ method: "POST", url: "/v1/categories", data });
};
export const editCategories = (data) => {
  return request({ method: "PUT", url: "/v1/categories", data });
};
export const createFilters = (data) => {
  return request({ method: "POST", url: "/v1/filters", data });
};
export const createAttributes = (data) => {
  return request({ method: "POST", url: "/v1/attributes", data });
};
export const editAttributes = (data) => {
  return request({ method: "PUT", url: "/v1/attributes", data });
};
export const createModerationProduct = (data) => {
  return request({ method: "POST", url: "/v1/moderations", data });
};

export const uploadPhoto = (data, id) => {
  return request({
    method: "POST",
    url: `/v1/files`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
