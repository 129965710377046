import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Input,
  Label,
} from "reactstrap";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getProductQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";

const ProductView = () => {
  const { productId } = useParams();
  const { t } = useTranslation();

  const { data: product } = useQuery({
    ...getProductQuery(productId),
  });
  console.log(product);

  document.title = "Product Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Client Details")} pageTitle={t("client")} />
        {product?.id && (
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("Информация о товаре")}
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="d-flex justify-content-between">
                  <Row className="d-flex flex-column gap-3 col-6">
                    <div>
                      <Label htmlFor="valueInput" className="form-label">
                        {"Категория товара"}
                      </Label>
                      <Input
                        type="text"
                        className="form-control fs-5"
                        id="valueInput"
                        defaultValue={product.category.name}
                        disabled={true}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </div>
                    {product?.names?.map((item) => (
                      <div key={item.languageCode}>
                        <Label htmlFor="valueInput" className="form-label">
                          {`Название на ${item.languageCode}`}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={item.text}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    ))}
                    {product?.descriptions?.map((item) => (
                      <div key={item.languageCode}>
                        <Label htmlFor="valueInput" className="form-label">
                          {`Описание на ${item.languageCode}`}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={item.text}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    ))}
                    {product?.variations[0]?.prices?.map((item) => (
                      <div key={item.languageCode}>
                        <Label htmlFor="valueInput" className="form-label">
                          {`${item.type}`}
                        </Label>
                        <Input
                          type="text"
                          className="form-control fs-5"
                          id="valueInput"
                          defaultValue={item.value}
                          disabled={true}
                          style={{
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    ))}
                    {product?.variations[0]?.files?.map((item) => (
                      <div
                        className="w-50 border form-control"
                        style={{ marginLeft: "0.8rem"}}
                        id="img2"
                        key={item.languageCode}
                      >
                        <img
                          src={item.url}
                          className="img-fluid w-100"
                          alt="Responsive"
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="d-flex flex-column gap-3 col-6">
                    {product?.variations[0]?.attributeValues
                      ?.filter((item) => item.attribute.isRequired === true)
                      .map((item) => (
                        <div key={item.attribute.id}>
                          <Label htmlFor="valueInput" className="form-label">
                            {`${item.attribute.name}`}
                          </Label>
                          <Input
                            type="text"
                            className="form-control fs-5"
                            id="valueInput"
                            defaultValue={item.value}
                            disabled={true}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          />
                        </div>
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ProductView;
