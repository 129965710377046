import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Input as InputFile,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createCategories, uploadPhoto, editCategories } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getCategoryQuery } from "../../../queries";

const schema = createSchema({
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  imageId: "",
});
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  imageId: "",
};

const CategoriesCreate = () => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, parentId } = useParams();
  const numbId = parseInt(id);
  const parent = parseInt(parentId);
  const QueryClient = useQueryClient();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useHookForm(values, schema);

  const { data: category } = useQuery({
    ...getCategoryQuery(id),
    enabled: id ? id !== undefined : parentId !== undefined,
  });

  const onSubmit = handleSubmit((res) => {
    if (res) {
      const names = Object.keys(res.name).map((languageCode) => ({
        languageCode,
        text: res.name[languageCode],
      }));
      setLoading(true);

      const formData = new FormData();
      formData.append("files", imageFile);

      uploadPhoto(formData).then((res) => {
        setLoading(false);
        const datas = id
          ? {
              id: numbId,
              name: names,
              imageId: res[0].id,
            }
          : parentId
          ? {
              parentId: parent,
              name: names,
              imageId: res[0].id,
            }
          : {
              name: names,
              imageId: res[0].id,
            };
        id
          ? editCategories(datas)
              .then((res) => {
                setLoading(false);
                QueryClient.invalidateQueries(["categories"]);
                navigate("/categories");
              })
              .catch((err) => alert(err.data?.error))
          : createCategories(datas)
              .then((res) => {
                setLoading(false);
                QueryClient.invalidateQueries(["categories"]);
                navigate("/categories");
              })
              .catch((err) => alert(err.data?.error));
      });
    }
  });
  console.log(category);
  useEffect(() => {
    if (category?.name?.length >= 3) {
      const values = {
        name: {
          ru: `${category.name[0].text}`,
          "uz-Cyrl-UZ": `${category.name[1].text}`,
          "uz-Latn-UZ": `${category.name[2].text}`,
        },
        imageId: "",
      };
      reset(values);
    }
  }, [category]);

  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {id ? "Изменить категорию" : "Добавить категорию"}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Input
                    name="name.ru"
                    control={control}
                    errors={errors}
                    label="name_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    name="name.uz-Cyrl-UZ"
                    control={control}
                    errors={errors}
                    label="name_uzc"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    name="name.uz-Latn-UZ"
                    control={control}
                    errors={errors}
                    label="name_uz"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <InputFile
                    name="imageId"
                    control={control}
                    errors={errors}
                    className="form-control form-control-sm"
                    id="formSizeSmall"
                    type="file"
                    onChange={(e) => setImageFile(e.target.files[0])}
                  />

                  <Button
                    color="success"
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesCreate;
