function findParentId(data, categoryId) {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].childs && data[i].childs.length > 0) {
        for (let j = 0; j < data[i].childs.length; j++) {
          if (data[i].childs[j].id === categoryId) {
            return [data[i]];
          }
        }
      }
    }

  }
  return null;
}
export default findParentId;

// const FiltersData = async () => {
//   const { item } = await fetch(
//     `
//   https://f.taqsim.uz/api/v1/filters?categoryId=${
//     Number.isInteger(childCategory) ? childCategory : childCategory[0][0].id
//   }`,
//     {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//         "Content-Type": "application/json",
//       },
//     }
//   ).then((res) => res.json());
//   setFiltersData(item);
// };