import request from "../utils/axios";

export const editOrganizationContract = (data, id) => {
  return request({
    method: "PUT",
    url: `/organizations/contracts/${id}`,
    data,
  });
};
export const createOrganizationContract = (data) => {
  return request({
    method: "POST",
    url: "/organizations/contracts?organizationId=undefined",
    data,
  });
};
export const editOrganizationState = (data, id) => {
  return request({
    method: "PUT",
    url: `/organizations/${id}/state`,
    data,
  });
};
export const editOrganization = (data, id) => {
  return request({
    method: "PUT",
    url: `/organizations/${id}`,
    data,
  });
};
