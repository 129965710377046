import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
} from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getCategoriesQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { deleteCategories } from "../../../api";
import { useNavigate } from "react-router-dom";

const schema = createSchema({});
const values = {};

const CategoriesList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { watch, setValue } = useHookForm(values, schema);
  const QueryClient = useQueryClient();

  const { data, isFetched } = useQuery({
    ...getCategoriesQuery({
      ...watch(),
    }),
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          const [isOpen, setIsOpen] = useState(false);
          const data = cell.row.original;
          function accordionMove() {
            return setIsOpen(!isOpen);
          }
          return (
            <Accordion id="default-accordion-example">
              <AccordionItem>
                <h2
                  className="accordion-header d-flex justify-content-between align-items-center"
                  id="headingOne"
                >
                  <button
                    className={classnames("accordion-button", {
                      collapsed: !isOpen,
                    })}
                    type="button"
                    onClick={accordionMove}
                    style={{ cursor: "pointer" }}
                  >
                    {cell.value}
                  </button>
                  <btn
                    className="ri-add-line fs-4 p-1"
                    onClick={() =>
                      navigate(`/categories/add/parent/${data.id}`)
                    }
                    style={{ cursor: "pointer" }}
                  />
                  <btn
                    className="ri-edit-line fs-4 p-1"
                    onClick={() => navigate(`/categories/edit/${data.id}`)}
                    style={{ cursor: "pointer" }}
                  />
                  <btn
                    className="ri-delete-bin-5-line fs-4 p-1"
                    onClick={() => {
                      deleteCategories(cell.row.original.id).then((res) => {
                        QueryClient.invalidateQueries(["categories"])
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </h2>
                <Collapse
                  isOpen={isOpen}
                  className="accordion-collapse"
                  id="collapseOne"
                >
                  {data?.childs?.map((item) => {
                    return (
                      <div
                        className="d-flex justify-content-between"
                        key={item.id}
                      >
                        <div className="accordion-body" key={item.id}>
                          {item.name}
                        </div>
                        <div className="accordion-body">
                          <btn
                            className="ri-edit-line fs-4 p-1"
                            onClick={() =>
                              navigate(`/categories/edit/${item.id}`)
                            }
                            style={{ cursor: "pointer" }}
                          />
                          {/* <btn
                            className="ri-add-line fs-4 p-1"
                            onClick={() =>
                              navigate(
                                `/categories/add/parent/${item.id}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          /> */}
                          <btn
                            className="ri-delete-bin-5-line fs-4 p-1"
                            onClick={() => {
                              deleteCategories(item.id).then((res) => {
                                QueryClient.invalidateQueries(["categories"])
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Collapse>
              </AccordionItem>
            </Accordion>
          );
        },
      },
    ],
    []
  );

  document.title = "Categories | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0 pb-0">
                <Row>
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("categories_list")}</h5>
                    <Link
                      to={"/categories/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{"Добавить"}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <TableContainer
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      divClass="table-responsive table-card mb-1"
                      tableClass="table-borderless align-middle table-nowrap mb-0"
                      useSortBy={false}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesList;
