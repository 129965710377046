import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getProductsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";


const schema = createSchema({});
const values = {
    page: 1,
    size: 15,
};

const Products = () => {
  const { t } = useTranslation();

  const { watch, setValue } = useHookForm(values, schema);

  const  { data, isFetched } = useQuery({
    ...getProductsQuery({
      ...watch(),
    }),
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("product_name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "organizationName",
        filterable: false,
      },
      {
          Header: t("product_category"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: t("isVisible"),
        accessor: "isVisible",
        filterable: false,  
        Cell: (cell) => {
            return (
              <div className="text-center" style={{ backgroundColor: cell.value === false ? "red" : "green", ...css.steteStyle}}>
                  {cell.value === false ? t("invisible") : t("visible")}
              </div>
              )
          }
      },
      {
        Header: t("States"),
        accessor: "state",
        filterable: false,
        Cell: (cell) => {
          return (
            <div className="text-center" style={{ backgroundColor: cell.value === 0 ? "red" : "green", ...css.steteStyle}}>
                {cell.value === 0 ? t("inactive") : t("active")}
            </div>
            )
        }
      },
      {
        Header: t("moderation_status"),
        accessor: "moderationStatus",
        filterable: false,
        Cell: (cell) => {
          return (
            <div className="text-center">
                {t(`${cell.value}`)}
            </div>
            )
        }
      },
      {
        Header: t("Action"),
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/products/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Products | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("products")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>

                  <CardBody className="border border-dashed border-end-0 border-start-0">
                    
                  </CardBody>
                  { isFetched ? (
                    <TableContainer 
                      pagination={{
                        currentPage: watch("page"),
                        totalPages: data?.pagination?.TotalPages,
                        onChange: (page) => setValue("page", page),
                      }}
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;

const css = {
    steteStyle: {
        color: "white", 
        borderRadius: "10px",
        fontSize: "0.7rem",
        padding: "2px 4px"
    }
}