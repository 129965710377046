import React from "react";
import QueryProvider from "./providers/query-provider";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

function App() {
  return (
    <React.Fragment>
      <QueryProvider>
        <Route />
      </QueryProvider>
    </React.Fragment>
  );
}

export default App;
