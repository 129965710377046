import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Input as InputFile,
  Label,
} from "reactstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createFilters } from "../../../api";
import { getCategoriesQuery } from "../../../queries";
import { Input } from "../../../Components/atoms/input";
import { useNavigate } from "react-router-dom";
import Cleave from "cleave.js/react";

const schema = createSchema({
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  type: "",
  values: "",
  weight: "",
  dataType: "",
  categoryId: "",
});
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  type: "",
  values: "",
  weight: "",
  dataType: "",
  categoryId: "",
};

const FiltersCreate = () => {
  const [parentCategory, setParentCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [dataType, setDataType] = useState("");
  const [weight, setWeight] = useState("");
  const navigate = useNavigate();
  const { data } = useQuery({
    ...getCategoriesQuery(),
  });
  const QueryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useHookForm(values, schema);

  const onSubmit = handleSubmit((res) => {
    const names = Object.keys(res.name).map((languageCode) => ({
      languageCode,
      text: res.name[languageCode],
    }));
    const values = res.values ? res.values : `${res.from}-${res.to}`;
    setLoading(true);

    const datas = {
      ...res,
      name: names,
      type: filterType,
      weight,
      dataType,
      categoryId: category[0][0].id,
      values: values,
    };

    createFilters(datas)
      .then(() => {
        setLoading(false);
        QueryClient.invalidateQueries(["filters"]);
        navigate("/filters");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  });

  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {"Добавить фильтр"}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Label>{"Выбор категории"}</Label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={(e) =>
                      setParentCategory(
                        data.filter(
                          (item) => item.id === parseInt(e.target.value)
                        )
                      )
                    }
                  >
                    <option>{"Выберите категорию"}</option>
                    {data?.map((item) => {
                      if (item.childs !== null) {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      }
                      return (
                        <option key={item.id}>{"ВЫберите категорию"}</option>
                      );
                    })}
                  </select>
                  {parentCategory && (
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      name="categoryId"
                      onChange={(e) =>
                        setCategory(
                          parentCategory.map((category) =>
                            category.childs.filter(
                              (item) => item.id === parseInt(e.target.value)
                            )
                          )
                        )
                      }
                    >
                      <option>{"Выберите категорию"}</option>
                      {parentCategory.map((item) =>
                        item.childs.map((category) => (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        ))
                      )}
                    </select>
                  )}
                  <Label>{"Тип фильтра"}</Label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={(e) => setFilterType(e.target.value)}
                  >
                    <option>{"Выберите тип фильтра"}</option>
                    <option value={"Range"}>{"Range"}</option>
                    <option value={"Price"}>{"Price"}</option>
                    <option value={"MultipleSelect"}>{"MultipleSelect"}</option>
                    <option value={"SingleSelect"}>{"SingleSelect"}</option>
                  </select>
                  <Label>{"Тип данных фильтра"}</Label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={(e) => setDataType(e.target.value)}
                  >
                    <option>{"Выберите тип фильтра"}</option>
                    <option value={"Text"}>{"Text"}</option>
                    <option value={"Integer"}>{"Integer"}</option>
                    <option value={"Float"}>{"Float"}</option>
                    <option value={"Date"}>{"Date"}</option>
                    <option value={"Bool"}>{"Bool"}</option>
                    <option value={"Char"}>{"Char"}</option>
                  </select>
                  <Label>{"Приоритет"}</Label>
                  <Cleave
                    placeholder="Enter numeral"
                    options={{
                      numeral: true,
                    }}
                    value={weight}
                    onChange={(e) => setWeight(parseInt(e.target.value))}
                    className="form-control"
                  />
                  <Label>{"Ключевые значения фильтров"}</Label>
                  {filterType === "Price" || filterType === "Range" ? (
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-end gap-3">
                        <Input
                          control={control}
                          errors={errors}
                          name="from"
                          inputProps={{
                            placeholder: t("enter value"),
                          }}
                        />
                        <span>dan</span>
                      </div>
                      <div className="d-flex align-items-end gap-3">
                        <Input
                          control={control}
                          errors={errors}
                          name="to"
                          inputProps={{
                            placeholder: t("enter value"),
                          }}
                        />
                        <span>gacha</span>
                      </div>
                    </div>
                  ) : (
                    <Input
                      control={control}
                      errors={errors}
                      name="values"
                      inputProps={{
                        placeholder: t("enter value"),
                      }}
                    />
                  )}
                  <Input
                    control={control}
                    errors={errors}
                    name="name.ru"
                    label="name_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="name.uz-Cyrl-UZ"
                    label="name_uzc"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="name.uz-Latn-UZ"
                    label="name_uz"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Button
                    color="success"
                    className="btn btn-primary w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FiltersCreate;
