import React, { useEffect, useState } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";

const AuthProtected = (props) => {
  const [token, setToken] = useState(true);
  const navigate = useNavigate();

  if (!token) {
    navigate("/login");
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
