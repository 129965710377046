import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Collapse,
  Label,
  Badge,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getOrderQuery, getProductCardQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import Textfield from "../ui/text-field";
import * as moment from "moment";
import Rating from "react-rating";
import { prettify } from "../../../helpers/price-formater";

const OrderView = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();

  const { data: order } = useQuery({
    ...getOrderQuery(orderId),
  });

  const { data: productCard } = useQuery({
    ...getProductCardQuery(order?.productId),
  });

  const CheckStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-warning">
            {t("orderStatus0")}
          </span>
        );
      case 1:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-danger">
            {t("orderStatus1")}
          </span>
        );
      case 2:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-secondary">
            {t("orderStatus2")}
          </span>
        );
      case 3:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-info">
            {t("orderStatus3")}
          </span>
        );
      case 4:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-primary">
            {t("orderStatus4")}
          </span>
        );
      case 5:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-success">
            {t("orderStatus5")}
          </span>
        );
      default:
        return (
          <span className="fs-6 badge text-uppercase badge-soft-warning">
            {t("orderStatus6")}
          </span>
        );
    }
  };

  document.title = "Order Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Order Details")} pageTitle={t("orders")} />
        {order?.id && (
          <Row>
            <Col xl={8}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("order_number")}
                      {order.id}
                      <span style={{ opacity: "0.5" }}>
                        {" "}
                        {" от"}
                        {` ${moment(order.createdDate).format("DD.MM.YYYY")}`}
                      </span>
                    </h5>
                    <div className="flex-shrink-0">
                      {CheckStatus(order.status)}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="col-md-10">
                    <Label style={css.title}>{t("about_organization")}</Label>
                    <Textfield
                      name={"organization_name"}
                      source={order.organization}
                    />
                  </div>
                  <div className="col-md-10">
                    <Label style={css.title}>{t("all_info")}</Label>
                    <Textfield name={"phone_number"} source={order.phone} />
                    <Textfield name={"full_name"} source={order.fullname} />
                    <Textfield
                      name={"dealType"}
                      source={
                        order.isInstallment
                          ? t("with_installment")
                          : t("with_compensation")
                      }
                    />
                    <Textfield name={"build_address"} source={order.address} />
                    <Textfield name={"Comments"} source={order.comment} />
                  </div>
                  {order.buildDetails && (
                    <div className="col-md-10">
                      <Label style={css.title}>{t("place_installing")}</Label>
                      <Textfield
                        name={"area_in_sqm"}
                        source={order.buildDetails.Area_in_sqm}
                      />
                      <Textfield
                        name={"choose_roof_type"}
                        source={t(order.buildDetails.Choose_roof_type)}
                      />
                      <Textfield
                        name={"choose_roof_type2"}
                        source={t(order.buildDetails.Choose_roof_type2)}
                      />
                      <Textfield
                        name={"type_building"}
                        source={t(order.buildDetails.Choose_building_type)}
                      />
                      <Textfield
                        name={"enter_usable_roof_area_sqm"}
                        source={order.buildDetails.Enter_usable_roof_area_sqm}
                      />
                      <Textfield
                        name={"select_installation_location"}
                        source={t(
                          order.buildDetails.Select_installation_location
                        )}
                      />
                      <Textfield
                        name={"choose_type_of_precrimination"}
                        source={t(
                          order.buildDetails.Choose_type_of_precrimination
                        )}
                      />
                      <Textfield
                        name={"choose_a_load_bearing_wall_material"}
                        source={t(
                          order.buildDetails.Choose_a_load_bearing_wall_material
                        )}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            {productCard?.prices?.find((item) => item.type === "PayAmount") && (
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <div className="col-md-12">
                      <Link to="#" className="avatar-group-item" id="img2">
                        <img
                          src={productCard?.files?.map((item) => item.url)}
                          className="img-fluid"
                          alt="Responsive"
                        />
                      </Link>
                      <Rating
                        initialRating={5}
                        emptySymbol="mdi mdi-star-outline text-muted "
                        fullSymbol="mdi mdi-star text-warning "
                        className="fs-4"
                      />
                    </div>
                    <p className="fs-4">{productCard?.product?.name}</p>
                    <p className="fs-5" style={{ color: "#FF8F00" }}>
                      {prettify(
                        productCard?.prices
                          ?.filter((item) => item.type === "Price")
                          .map((item) => item.value)
                      )}
                    </p>
                    <p style={{ opacity: 0.4, fontSize: "15px" }}>
                      {t("pay_amount")}
                    </p>
                    <p className="fs-5">
                      {prettify(
                        productCard?.prices
                          ?.filter((item) => item.type === "PayAmount")
                          .map((item) => item.value)
                      )}
                    </p>
                    <p style={{ opacity: 0.4, fontSize: "15px" }}>
                      {t("price_installment")}
                    </p>
                    <div className="d-flex align-items-start">
                      {productCard?.compensationOnly ? (
                        <>{"Не доступно"}</>
                      ) : (
                        <>
                          <p className="fs-5">
                            {prettify(
                              productCard?.prices
                                ?.filter((item) => item.type === "Installment")
                                .map((item) => item.value / 36)
                            )}
                          </p>
                          <Badge color="warning" style={{ fontSize: "0.7rem" }}>
                            {"x 36 месяц"}
                          </Badge>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default OrderView;

const css = {
  title: {
    fontSize: "20px",
    fontWeight: 700,
    margin: "20px 0",
  },
};
