import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, CardHeader, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getAttributesQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";

const schema = createSchema({});
const values = { page: 1, size: 15 };

const AttributesList = () => {
  const { t } = useTranslation();

  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getAttributesQuery({
      ...watch(),
    }),
  });
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to="/apps-ecommerce-order-details"
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: t("name"),
        accessor: "description",
        filterable: false,
      },
      {
        Header: t("weight"),
        accessor: "weight",
        filterable: false,
      },
      {
        Header: t("hasFilter"),
        accessor: "hasFilter",
        filterable: false,
        Cell: (cell) => {
          return cell.value ? <>{t("yes")}</> : <>{t("no")}</>;
        },
      },
      {
        Header: t("isVisible"),
        accessor: "isVisible",
        filterable: false,
        Cell: (cell) => {
          return cell.value ? <>{t("yes")}</> : <>{t("no")}</>;
        },
      },
      {
        Header: t("type"),
        accessor: "type",
        filterable: false,
      },
      {
        Header: t("Action"),
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/attributes/edit/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-edit-line fs-4 p-1 fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Attributes | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("attributes_group")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("attributes_group")}</h5>
                    <Link
                      to={"/attributes/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{"Добавить"}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <TableContainer
                      pagination={{
                        currentPage: watch("page"),
                        totalPages: data?.pagination?.TotalPages,
                        onChange: (page) => setValue("page", page),
                      }}
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttributesList;
