import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Spinner, Label, Form } from "reactstrap";
import { Input } from "../../../Components/atoms/input";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import * as moment from "moment";
import "cleave.js/dist/addons/cleave-phone.uz";
import {
  editOrganizationContract,
  editOrganizationState,
  createOrganizationContract,
} from "../../../api/organization";

const schema = createSchema({});
const values = {
  agreement1: {
    contractNumber: "",
    organizationId: "",
    saleType: 1,
    contractDate: "",
  },
  agreement2: {
    contractNumber: "",
    organizationId: "",
    saleType: 0,
    contractDate: "",
  },
};

const OrgContractEdit = ({ organization }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useHookForm(values, schema);

  const onSubmitContract = handleSubmit((res) => {
    setLoading(true);
    const data = {
      id: parseInt(id),
      state,
    };
    res.agreementId1
      ? editOrganizationContract(res.agreement1, res.agreementId1).then(
          setLoading(false)
        )
      : res.agreement1.contractNumber &&
        createOrganizationContract({
          ...res.agreement1,
          organizationId: parseInt(id),
        }).then(setLoading(false));
    res.agreementId2
      ? editOrganizationContract(res.agreement2, res.agreementId2).then(
          setLoading(false)
        )
      : res.agreement2.contractNumber &&
        createOrganizationContract({
          ...res.agreement1,
          organizationId: parseInt(id),
        }).then(setLoading(false));
    editOrganizationState(data, id).then(setLoading(false));
  });

  const organizationArray = organization?.contracts
    ? organization.contracts.filter(
        (item) => item.saleType === 1 || item.saleType === 0
      )
    : [];

  useEffect(() => {
    organization && setState(organization.state);
    if (organizationArray && organizationArray.length > 0) {
      const values = {
        agreement1: {
          contractNumber: organizationArray[0].contractNumber,
          organizationId: parseInt(id),
          saleType: parseInt(organizationArray[0].saleType),
          contractDate: moment(organizationArray[0].contractDate).format(
            "YYYY.MM.DD"
          ),
        },
        agreementId1: organizationArray[0].id,
        agreement2: {
          contractNumber:
            organizationArray.length > 1
              ? organizationArray[1].contractNumber
              : "",
          organizationId: parseInt(id),
          saleType:
            organizationArray.length > 1
              ? parseInt(organizationArray[1].saleType)
              : 0,
          contractDate:
            organizationArray.length > 1
              ? moment(organizationArray[1].contractDate).format("YYYY.MM.DD")
              : "",
        },
        ...(organizationArray.length > 1 && {
          agreementId2: organizationArray[1].id,
        }),
        filterType: {
          id: parseInt(id),
          state: state,
        },
      };
      reset(values);
    }
  }, [organization]);

  document.title = "Organizations-Contract Edit | Taqsim";
  return (
    <Form action="#" className="d-flex flex-column gap-3">
      <Card>
        <Row className="d-flex flex-column align-items-start p-4 gap-4">
          <h4
            className="card-title flex-grow-1 mb-0 text-dark"
            style={{ fontWeight: 600 }}
          >
            {"Договора"}
          </h4>
          <Row className="w-100 d-flex justify-content-between">
            <Col xl={5}>
              <Row>
                <div>
                  <Label htmlFor="valueInput" className="form-label p-0">
                    {"Номер договора для компенсации"}
                  </Label>
                  <Input
                    type="text"
                    className="form-control mb-3"
                    id="valueInput"
                    name="agreement1.contractNumber"
                    errors={errors}
                    control={control}
                  />
                </div>
                <div>
                  <Label htmlFor="valueInput" className="form-label p-0">
                    {"Номер договора для рассрочки"}
                  </Label>
                  <Input
                    type="text"
                    className="form-control mb-3"
                    id="valueInput"
                    name="agreement2.contractNumber"
                    errors={errors}
                    control={control}
                  />
                </div>
              </Row>
            </Col>
            <Col xl={5}>
              <Row>
                <div>
                  <Label htmlFor="valueInput" className="form-label p-0">
                    Дата договора
                  </Label>
                  <Input
                    type="date"
                    className="form-control mb-3"
                    id="exampleInputdate"
                    name="agreement1.contractDate"
                    errors={errors}
                    control={control}
                  />
                </div>
                <div>
                  <Label htmlFor="valueInput" className="form-label p-0">
                    Дата договора
                  </Label>
                  <Input
                    type="date"
                    className="form-control mb-3"
                    id="exampleInputdate"
                    name="agreement2.contractDate"
                    errors={errors}
                    control={control}
                  />
                </div>
              </Row>
            </Col>
            <Col xl={5}>
              <Label>{"Тип фильтра"}</Label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                onChange={(e) => setState(parseInt(e.target.value))}
                value={state}
              >
                <option value={1}>{"Активный"}</option>
                <option value={2}>{"Не активный"}</option>
              </select>
            </Col>
          </Row>
          <Col xl={3}>
            <Button
              color="success"
              className="btn btn-success w-100"
              type="submit"
              onClick={onSubmitContract}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              {t("save")}
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default OrgContractEdit;
