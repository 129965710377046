import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Input as InputFile,
  Label,
} from "reactstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createAttributes, editAttributes } from "../../../api";
import { getCategoriesQuery, getAttributeQuery } from "../../../queries";
import { Input } from "../../../Components/atoms/input";
import { useNavigate, useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import findParentId from "../../../helpers/findParentCategoryId";

const schema = createSchema({
  weight: "",
});

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  hasFilter: "",
  isRequired: "",
  weight: "",
  dataType: "",
  categoryId: "",
  isValueTranslated: "",
};

const AttributeCategory = () => {
  const [parentCategory, setParentCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [dataType, setDataType] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filtersData, setFiltersData] = useState([]);
  const [weight, setWeight] = useState("");
  const [hasFilter, setHasFilter] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const { id } = useParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useHookForm(values, schema);

  // FETCHING
  const { data } = useQuery({
    ...getCategoriesQuery(),
  });
  const { data: attribute } = useQuery({
    ...getAttributeQuery(id),
    enabled: id !== undefined,
  });

  const FiltersData = async () => {
    const { item } = await fetch(
      `
    https://f.taqsim.uz/api/v1/filters?categoryId=${
      Number.isInteger(category) ? category : category[0][0].id
    }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
    setFiltersData(item);
  };

  const onSubmit = handleSubmit((res) => {
    const names = Object.keys(res.name).map((languageCode) => ({
      languageCode,
      text: res.name[languageCode],
    }));
    const descriptions = Object.keys(res.description).map((languageCode) => ({
      languageCode,
      text: res.description[languageCode],
    }));
    setLoading(true);

    const datas = {
      ...res,
      name: names,
      weight,
      dataType,
      categoryId: Number.isInteger(category) ? category : category[0][0].id,
      description: descriptions,
      isRequired,
      hasFilter,
      isValueTranslated: isRequired,
      ...(filterId && { filterId }),
      ...(id && { id: parseInt(id) }),
    };

    id
      ? editAttributes(datas)
          .then(() => {
            setLoading(false);
            QueryClient.invalidateQueries(["attributes"]);
            navigate("/attributes");
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          })
      : createAttributes(datas)
          .then(() => {
            setLoading(false);
            QueryClient.invalidateQueries(["attributes"]);
            navigate("/attributes");
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
  });

  useEffect(() => {
    if (attribute) {
      setParentCategory(findParentId(data, attribute?.categoryId));
      setCategory(attribute.categoryId);
      setWeight(attribute.weight);
      setHasFilter(attribute.hasFilter);
      setIsRequired(attribute.isRequired);
      attribute.filterId && setFilterId(attribute.filterId);
      const values = {
        name: {
          ru: `${attribute?.name[0].text}`,
          "uz-Cyrl-UZ": `${attribute?.name[1].text}`,
          "uz-Latn-UZ": `${attribute?.name[2].text}`,
        },
        description: {
          ru: `${attribute?.name[0].text}`,
          "uz-Cyrl-UZ": `${attribute?.name[1].text}`,
          "uz-Latn-UZ": `${attribute?.name[2].text}`,
        },
        hasFilter: `${attribute.hasFilter}`,
        isRequired: `${attribute.isRequired}`,
        weight: `${attribute.weight}`,
        dataType: `${attribute.dataType}`,
        isValueTranslated: `${attribute.isValueTranslated}`,
      };
      reset(values);
    }
  }, [attribute]);

  console.log(dataType);

  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {"Добавить атрибут"}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Label>{"Выбор категории"}</Label>
                  {/* CATEGORY */}
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={(e) =>
                      setParentCategory(
                        data.filter(
                          (item) => item.id === parseInt(e.target.value)
                        )
                      )
                    }
                    value={
                      parentCategory && parentCategory.length > 0
                        ? parentCategory[0].id
                        : ""
                    }
                  >
                    <option>{"Выберите категорию"}</option>
                    {data?.map((item) => {
                      if (item.childs !== null) {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      }
                      return (
                        <option key={item.id}>{"ВЫберите категорию"}</option>
                      );
                    })}
                  </select>
                  {parentCategory && (
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      name="categoryId"
                      onChange={(e) =>
                        setCategory(
                          parentCategory?.map((category) =>
                            category.childs.filter(
                              (item) => item.id === parseInt(e.target.value)
                            )
                          )
                        )
                      }
                      defaultValue={category}
                    >
                      <option>{"Выберите категорию"}</option>
                      {parentCategory?.map((item) =>
                        item.childs.map((category) => (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        ))
                      )}
                    </select>
                  )}
                  {/* FILTERS */}
                  <Label>{"Фильтр"}</Label>

                  <div className="form-check">
                    <InputFile
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      id="flexRadioDefault2"
                      defaultChecked
                      onChange={() => setHasFilter(false)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Нет
                    </Label>
                  </div>
                  <div className="form-check mb-2">
                    <InputFile
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault2"
                      id="flexRadioDefault2"
                      onChange={() => setHasFilter(true)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Есть
                    </Label>
                  </div>

                  {hasFilter ? (
                    <>
                      <Label>{"Выбор фильтров"}</Label>
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        onChange={(e) => {
                          const type = filtersData?.filter(
                            (item) => item.id === parseInt(e.target.value)
                          );
                          setFilterId(parseInt(e.target.value));
                          setDataType(type[0].dataType);
                        }}
                        defaultValue={filterId}
                        onFocus={FiltersData}
                      >
                        <option>{"Выберите тип фильтра"}</option>
                        {filtersData?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <>
                      <Label>{"Тип данных фильтра"}</Label>
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setDataType(e.target.value);
                          setFilterId();
                        }}
                      >
                        <option>{"Выберите тип фильтра"}</option>
                        <option value={"Text"}>{"Text"}</option>
                        <option value={"Integer"}>{"Integer"}</option>
                        <option value={"Float"}>{"Float"}</option>
                        <option value={"Date"}>{"Date"}</option>
                        <option value={"Bool"}>{"Bool"}</option>
                        <option value={"Char"}>{"Char"}</option>
                      </select>
                    </>
                  )}

                  <Label>{"Приоритет"}</Label>
                  <Cleave
                    placeholder="Enter numeral"
                    options={{
                      numeral: true,
                    }}
                    value={weight}
                    onChange={(e) => setWeight(parseInt(e.target.value))}
                    className="form-control"
                  />

                  <Label>{"Обязательное поле"}</Label>
                  <div className="form-check">
                    <InputFile
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="flexRadioDefault1"
                      defaultChecked
                      onChange={() => setIsRequired(false)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Нет
                    </Label>
                  </div>
                  <div className="form-check mb-2">
                    <InputFile
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault1"
                      id="flexRadioDefault1"
                      onChange={() => setIsRequired(true)}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Есть
                    </Label>
                  </div>

                  {/* NAME & DESCRITPION TRANSLATE */}
                  <Input
                    control={control}
                    errors={errors}
                    name="name.ru"
                    label="name_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="name.uz-Cyrl-UZ"
                    label="name_uzc"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="name.uz-Latn-UZ"
                    label="name_uz"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Input
                    control={control}
                    errors={errors}
                    name="description.ru"
                    label="description_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Input
                    control={control}
                    errors={errors}
                    name="description.uz-Cyrl-UZ"
                    label="description_uzc"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Input
                    control={control}
                    errors={errors}
                    name="description.uz-Latn-UZ"
                    label="description_uz"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Button
                    color="success"
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttributeCategory;
